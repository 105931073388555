const initialState = {
  loading: false,
  notifications: []
};

export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'notifications/setNotifications':
      return { ...state, notifications: action.payload };
    default:
      return state;
  }
};
