import { API_SUPPORT } from '../../../../utils';
import Swal from 'sweetalert2';

/**
 *
 * @param {*} pageId
 */
export const updateTokens = async (danielToken, robinToken) => {
  const tokens = {
    danielToken: danielToken,
    robinToken: robinToken
  };
  try {
    await fetch(API_SUPPORT, {
      method: 'POST',
      body: JSON.stringify({ tokens })
    });
    Swal.fire({
      icon: 'success',
      title: 'Update success',
      showConfirmButton: false,
      timer: 3500
    });
  } catch (error) {
    console.error('An error has ocurred', error);
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: 'We cannot update'
    });
  }
};
